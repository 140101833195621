<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props:{
    labels:{
      default:['Desktops', 'Tablets'],
    },
    data:{
      default: [1,5]
    }
  },
  watch:{
    labels: function () { // watch it
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                data: this.data,
                backgroundColor: ['#34c38f', '#ebeff2'],
                hoverBackgroundColor: ['#34c38f', '#ebeff2'],
                hoverBorderColor: '#fff',
              },
            ],
          },
          {
            maintainAspectRatio: true,
            responsive: false,
          }
      )
    },
    data: function () { // watch it
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                data: this.data,
                backgroundColor: ['#34c38f', '#f2ebeb'],
                hoverBackgroundColor: ['#34c38f', '#ebeff2'],
                hoverBorderColor: '#fff',
              },
            ],
          },
          {
            maintainAspectRatio: false,
            responsive: false,
          }
      )
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            backgroundColor: ['#34c38f', '#ebeff2'],
            hoverBackgroundColor: ['#34c38f', '#ebeff2'],
            hoverBorderColor: '#fff',
          },
        ],
      },
      {
        maintainAspectRatio: true,
        responsive: true,
      }
    )
  },
}
</script>